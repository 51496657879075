html,body {
  overflow-x:hidden;
  min-width: 340px;
}

.App {
  background-color: #333;
  color: #eee;
}

@font-face {
  font-family: 'Bicubik';
  src: url('fonts/Bicubik.woff') format('woff'),
  url('fonts/Bicubik.otf') format('opentype'),
  url('fonts/Bicubik.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk-Light';
  src: url('fonts/HKGrotesk-Light.woff') format('woff'),
  url('fonts/HKGrotesk-Light.otf') format('opentype'),
  url('fonts/HKGrotesk-Light.ttf') format('truetype');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position:relative;
}

.App-header > img {
  position: absolute;
  width: 100%;
  animation: slide 16s infinite;
}


.App-header > img:nth-child(0) {
  animation-delay: 0s;
}

.App-header > img:nth-child(3) {
  animation-delay: 4s;
}

.App-header > img:nth-child(2) {
  animation-delay: 8s;
}

.App-header > img:nth-child(1) {
  animation-delay: 12s;
}


@keyframes slide {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.App-link {
  color: #61dafb;
}

.bg-primary {
  background-color: #333;
}
.bg-secondary {
  background-color: #2cb34b;
}
.bg-tertiary {
  background-color: #3381ac;
}
.bg-quartary {
  background-color: #ffbd4a;
}
.white-border {
  border: white solid 1px;
}

.utility-top-padding {
  padding-top: 12px;
}

.h2-center-padding,
.h3-center-padding {
  padding-top: 20px;
}

footer {
  background-color: #222;
}

.App-connect-wallet {
  position: absolute;
  padding-top: 10px;
  width:100%;
}

.App-connect-wallet-button {
  float:right;
  padding: 10px;
}

.section {
  margin:0;
  padding: 30px;
  min-height: 350px;
}

.copyright {
  text-align: center;
}


@media (max-width:600px) {
  .section {
    min-height:300px;
  }
}
img.us {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height:300px;
  width:300px;
}

.button-spinner-wrapper{
  position: relative;
}

.loading-spinner {
  top:50%;
  left:50%;
  margin-top:-12px;
  margin-left:-12px;
  position:absolute;
}

.MintJockey-sample {
  height:100%;
  width:100%;
  pointer-events: none;
}

@keyframes header-animation-right
{
  0% {
    left: -20%;
    opacity: 1;
    animation-timing-function: ease-in;
  }
  50% {
    opacity: 0.8;
    animation-timing-function: ease-in;
  }
  100% {
    opacity: 0;
    left: 100%;
    animation-timing-function: ease-out;
  }
}


@-webkit-keyframes header-animation-right
{
  0% {
    left: -20%;
    opacity: 1;
    -webkit-animation-timing-function: ease-in;
  }
  50% {
    opacity: 0.8;
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    opacity: 0;
    left: 100%;
    -webkit-animation-timing-function: ease-out;
  }
}

.lane-1 {
  top:0;
}
.lane-2 {
  top:15%;
}
.lane-3 {
  top:30%;
}
.lane-4 {
  top:45%;
}
.lane-5 {
  top:60%;
}
.logo-container {
  overflow: hidden;
  height:100%;
}
.header-img {
  opacity: 0;
  position:absolute;
  height: 40vmin;
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.speed-1 {
  animation: header-animation-right 1.5s;
  -webkit-animation: header-animation-right 1.5s;
}
.speed-2 {
  animation: header-animation-right 2s;
  -webkit-animation: header-animation-right 2s;
}
.speed-4 {
  animation: header-animation-right 1.75s;
  -webkit-animation: header-animation-right 1.75s;
}
.speed-3{
  animation: header-animation-right 1.4s;
  -webkit-animation: header-animation-right 1.4s;
}
.delay-0-5 {
  animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
}
.delay-0-75 {
  animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
}
.delay-1-0 {
  animation-delay: 1.0s;
  -webkit-animation-delay: 1.0s;
}
.delay-0-25 {
  animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
}
.count-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
}
.count-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: 3;
}
.count-4 {
  animation-iteration-count: 4;
  -webkit-animation-iteration-count: 4;
}

footer {
  text-align: center;
  padding: 30px;
}
.socials {
  font-size: 32px;
}
.socials > .discord {
  color:#5865F2;
}
.socials > .twitter {
  color:#1DA1F2;
}
.socials > a {
  padding:0px 30px;
}
img.roadmap {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width:750px;
}

.lt-blue {
  color:#a0dcff;
}
.bg-lt-blue {
  background-color: #a0dcff;
}
.dk-blue {
  color:#45bbff;
}
.bg-dk-blue {
  background-color:#45bbff;
}
.dk-green {
  color:#46cd6e;
}
.bg-dk-green {
  background-color:#46cd6e;
}
.yellow {
  color:#ffbd4a;
}
.bg-yellow {
  background-color:#ffbd4a;
}

div.bounding-box {
  position:relative;
  overflow: hidden;
  height:280px;
  width:100%;
}

img.waves {
  position:absolute;
  width:100%;
  z-index: 0;
}
.section-header img.waves {
  margin-top: -25px;
}

img.utility-waves {
  margin-top: -25px;
  position:absolute;
  width:100%;
  z-index: 0;
}

@media (max-width:1500px){
  .App-header > img {
    height: 300px;
    width: auto;
  }

  img.waves {
    height: 300px;
    width: auto;
    z-index: 0;
  }
}

.header-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.section-header-container {
  padding-top:75px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.black-background {
  z-index: 1;
  padding:8px 10px;
  background-color:black;
  border-radius:10px;
}

.inner-border {
  padding:15px;
  border:solid 1px;
  border-radius:7px;
}

@media (max-width:750px){
  img.roadmap {
    width:100%;
  }
}

#community, #art {
  padding-top:30px;
}